import React, { useState, useEffect, useRef } from 'react';

import Layout from '../components/layout/main';

import HomeSecurityCarousel from '../components/content/home-security/home-security-carousel';
import Intro from '../components/widgets/intro';
import ContentTitle from '../components/widgets/content-title';
import SecurityBanner from '../components/content/home-security/security-banner';
import AutomationBanner from '../components/content/home-security/automation-banner';
import IntegrationBanner from '../components/content/home-security/integration-banner';

import ContactUs from '../components/widgets/contact-us';
import GettingStarted from '../components/widgets/getting-started';
import Head from '../components/layout/head';

import getWindowDimensions from '../utils/getWindowDimensions';

const HomeSecurity = () => {
  const [windowDimensions, setWindowDimensions] = useState();

  useEffect(() => {
    if (window !== null) {
      setWindowDimensions(getWindowDimensions());
    }
  }, []);

  const scrollToImmersive = (ref) => {
    if (window !== null) {
      window.scrollTo(
        0,
        ref.current.offsetTop - (windowDimensions.width <= 767 ? 70 : 130)
      ); // 154px is header height. Need to make this dynamic
    }
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const onclick1 = (evt) => {
    evt.preventDefault();
    scrollToImmersive(ref1);
  };

  const onclick2 = (evt) => {
    evt.preventDefault();
    scrollToImmersive(ref2);
  };

  const onclick3 = (evt) => {
    evt.preventDefault();
    scrollToImmersive(ref3);
  };

  return (
    <Layout>
      <Head title={'Home Security'} />

      <HomeSecurityCarousel />
      <Intro
        intro={<>What can excellent home security do for you?</>}
        title1="Security"
        title2="Automation"
        title3="Integration"
        onclick1={onclick1}
        onclick2={onclick2}
        onclick3={onclick3}
      />

      <div ref={ref1} />
      <div className="bg-ganen-gray-2">
        <ContentTitle title="Security" bgColor="#efefee" />
        <SecurityBanner />
      </div>

      <div ref={ref2} />
      <div className="bg-white">
        <ContentTitle title="Automation" bgColor="white" />
        <AutomationBanner />
      </div>

      <div ref={ref3} />
      <div className="bg-ganen-gray-2">
        <ContentTitle title="Integration" bgColor="#efefee" />
        <IntegrationBanner />
      </div>

      <ContactUs />
      <GettingStarted />
    </Layout>
  );
};

export default HomeSecurity;
