import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const IntegrationBanner = () => {
  const { securitytouch } = useStaticQuery(graphql`
    query {
      securitytouch: file(relativePath: { eq: "security-touch.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="bg-ganen-gray-2">
      <div className="box-container">
        <div className="row h-500 h-md-550 px-3">
          <div className="col-12 col-lg-6">
            <BackgroundImage
              fluid={securitytouch.childImageSharp.fluid}
              className="h-400 h-md-270 w-md-75 mx-auto"
            ></BackgroundImage>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <p className="py-1 pt-3">
              <em>“Everything works better together.”</em>
            </p>

            <div className="y-2 p-lg-4">
              <div>
                Integration is key to home security. When your security system
                works together with the locks, lights, cameras and curtains or
                blinds, you create an integrated nest that gives you the best
                protection possible.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationBanner;
