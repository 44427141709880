import React from 'react';

import HomeSecurityBanner1 from './home-security-banner-1';
import HomeSecurityBanner2 from './home-security-banner-2';
import MerryGoRound from '../../widgets/merry-go-round';

const HomeSecurityCarousel = () => {
  const items = [
    {
      key: 'index-1',
      src: '',
      content: <HomeSecurityBanner1 />,
    },
    {
      key: 'index-2',
      src: '',
      content: <HomeSecurityBanner2 />,
    },
  ];

  return (
    <section>
      <MerryGoRound items={items} fixHeight={500} />
    </section>
  );
};

export default HomeSecurityCarousel;
