import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import CallSVG from '../../../images/call.svg';

const HomeSecurityBanner1 = () => {
  const { site, image } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          contactNumber
        }
      }
      image: file(relativePath: { eq: "c6-secured-house.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      fluid={image.childImageSharp.fluid}
      className="home-automation-banner"
      style={{ height: '500px' }}
    >
      <div className="box-container d-flex h-100 mx-auto">
        <div className="banner-content text-white pl-30 mw-570 flex-column align-self-center">
          <h1 className="fs-60 ganen-bold mb-0">Home security.</h1>

          <div className="fs-20 ganen-bold pb-3">
            Safe, robust, and fully customisable
          </div>

          <span className="d-block fs-14">
            Call now to customise your system.
          </span>
          <a
            className="call-button-link fs-20 btn bg-ganen-brown rounded-10 text-white px-4"
            href={`tel:${site.siteMetadata.contactNumber}`}
          >
            <CallSVG className="hw-20 pr-t-3" />{' '}
            {`${site.siteMetadata.contactNumber}`}
          </a>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default HomeSecurityBanner1;
