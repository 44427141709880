import React from 'react';

const AutomationBanner = () => {
  return (
    <div>
      <div className="box-container">
        <div className="row h-500 h-md-550 px-3">
          <div className="col-12 col-lg-6 text-center">
            <p className="py-1 pt-3">
              <em>
                “Control and monitor your security system
                <br className="d-none d-sm-block" />
                remotely anywhere”
              </em>
            </p>

            <div className="py-2 p-lg-4">
              Never forget to arm your home again. Remotely arm your house and
              remotely monitor your house anywhere with our GANEN mobile APP.
              You can also arm your home with your home automation command, or
              simply arm it when you say “I’m Leaving”.
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="embed-responsive embed-responsive-16by9 w-100 h-270 mt-5">
              <video className="embed-responsive-item" controls>
                <source
                  src="/videos/ganen-gate-automation.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomationBanner;
